*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body{
    background: url('../../img/login1.jpg') center center/cover;
    font-family: Roboto, sans-serif;
    line-height: 1.8;
    height: 100%;
    position: relative;
}

a{
    text-decoration: none;
}

h1{
    padding-left: 1rem;
    /*padding: 0.3rem;*/
}

#container{
    border-top: darkseagreen;
    margin: 30px auto;
    max-width: 400px;
    padding: 20px;
    min-height: 100vh;
    overflow: hidden;
    display: block;
    position: relative;
}

.form-wrap{
    background: white;
    padding: 15px 25px;
    color: #333;
}

.form-wrap h1,
.form-wrap p{
    text-align: center;
}

.form-wrap .form-group{
    margin-top: 15px;
}

.form-wrap .form-group label{
    display: block;
    color: #666;
}

.form-wrap .form-group input{
    width: 100%;
    border: #ddd 1px solid;
    border-radius: 5px;
    display: block;
    padding: 10px;
}

.form-wrap button{
    width: 95%;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 10px;
    margin-top: 20px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn{
    width: 95%;
    text-align: center;
    margin-top: 1rem;
}

.form-wrap button:hover{
    background: #93cb52;
}

.form-wrap .bottom-text{
    font-size: 13px;
    margin-top: 20px;
}

.footer{
    text-align: center;
    margin-top: 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.footer a{
    background: #93cb52;
}
