*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body{
    font-family: 'Roboto', sans-serif;
    line-height: 1.4;
}

a{
    text-decoration: none;
}

p{
    margin: 0.75rem 0;
}

/* Utility Classes */
.text-primary{
    color: #93cb52;
}

.text-center{
    text-align: center;
}

.bg-light{
    background: #f4f4f4;
    color: #333;
}

.bg-dark{
    background: #333;
    color: #fff;
}

.bg-primary{
    background: #93cb52;
    color: #fff;
}

.m-heading{
    font-size: 2rem;
    margin-bottom: 0.75rem;
    line-height: 1.1;
}

.l-heading{
    font-size: 3.5rem;
    margin-bottom: 0.75rem;
    line-height: 1.1;
}

.lead{
    font-size: 1.3rem;
}

.py-1 {padding: 1.5rem 0; }
.py-2 {padding: 2rem 0; }
.py-3 {padding: 3rem 0; }

.p-1 {padding: 1.5rem; }
.p-2 {padding: 2rem; }
.p-3 {padding: 3rem; }

.btn{
    display: inline-block;
    color: #fff;
    background: #93cb52;
    padding: 0.5rem 2rem;
    margin: 0.5rem;
    border: none;
    border-radius: 5px;
}
.btn-container{
    display: flex;
    flex-direction: column;
}
.btn:hover{
    background: #7ab436;
}

.btn-dark{
    background: #333;
    color: #fff;
}

.list{
    margin: 0.5rem 0;
    list-style: none;
}

.list li{
    padding: 0.5rem;
    border-bottom: #444 dotted 1px;
}
.container{
    max-width: 1100px;
    margin: auto;
    padding: 0 2rem;
    overflow: hidden;

}

/* Navbar */
#navbar{
    display: flex;
    position: sticky;
    top: 0;
    background: #333;
    color: #fff;
    justify-content: space-between;
    z-index: 1;
    padding: 1rem;
}

#navbar ul{
    display: flex;
    align-items: center;
    list-style: none;
}

#navbar li{
    align-items: flex-end;
    list-style: none;
}

#navbar ul li a{
    color: #fff;
    padding: 0.75rem;
    margin: 0 0.25rem;
}

#navbar ul li a:hover{
    background: #93cb52;
    border-radius: 5px;
}

/* Showcase */
#showcase{
    background: #333 url('../../img/home1.jpg') no-repeat center center/cover;
    min-height: 100vh;
    color: #fff;
    display: flex;
    flex-direction: column;
    flex: 1;
}

#showcase .showcase-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    min-height: 100vh;
    padding: 0 2rem;
    /* Overlay */
    background: linear-gradient(
            rgba(0, 0, 0, 0.45),
            rgba(0, 0, 0, 0.45)
    );
}